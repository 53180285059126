import React from "react"
import { useQueryParam, NumberParam } from "use-query-params"

import AllProducts from "../../components/Store/Products/AllProducts"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { FooterStore } from "../../components/Store/FooterStore"
import NavbarStore from "../../components/Store/NavbarStore"
import PreFooter from "../../components/Store/PreFooter"
import FooterSmall from "../../components/FooterSunset"
import SEO from "../../components/seo"

function Productos() {
  const { width } = useWindowDimensions()
  const [c] = useQueryParam("c", NumberParam)

  return (
    <div style={{height: "auto","minHeight": "100vh","display": "flex","flexDirection": "column"}}>
      <SEO title="Productos | Corona Store" />
      <NavbarStore width={width} />
      <AllProducts categoriaInicial={c} />
      {width <= 500 ? (
        <FooterSmall styles={{ paddingTop: "150px" }} />
      ) : (
        <FooterStore />
      )}
    </div>
  )
}

export default Productos
